export default function EyeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m.191 2.063.56.498 13.5 12 .561.498.997-1.121-.56-.498-1.81-1.608 2.88-3.342v-.98l-3.204-3.72C10.645.923 6.365.686 3.594 3.08L1.748 1.44 1.188.94.19 2.063ZM14.761 8l-2.442 2.836-1.65-1.466a3.001 3.001 0 0 0-4.342-3.86l-1.6-1.422a5.253 5.253 0 0 1 7.251.682L14.76 8ZM7.526 6.576l1.942 1.727a1.499 1.499 0 0 0-1.942-1.727Zm-7.845.935 1.722-2 1.137.979L1.24 8l2.782 3.23A5.25 5.25 0 0 0 9.9 12.703l.54 1.4a6.751 6.751 0 0 1-7.555-1.892L-.318 8.49v-.98Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
