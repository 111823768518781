export default function GearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m9.7 3.736.045-.236h.51l.044.236a2.024 2.024 0 0 0 1.334 1.536c.19.066.375.143.554.23.618.301 1.398.29 2.03-.143l.199-.136.36.361-.135.199a2.024 2.024 0 0 0-.143 2.03c.087.179.164.364.23.554.224.65.783 1.192 1.536 1.334l.236.044v.51l-.236.044a2.024 2.024 0 0 0-1.536 1.334 4.95 4.95 0 0 1-.23.554 2.024 2.024 0 0 0 .143 2.03l.136.199-.361.36-.199-.135a2.024 2.024 0 0 0-2.03-.143c-.179.087-.364.164-.554.23a2.024 2.024 0 0 0-1.334 1.536l-.044.236h-.51l-.044-.236a2.024 2.024 0 0 0-1.334-1.536 4.952 4.952 0 0 1-.554-.23 2.024 2.024 0 0 0-2.03.143l-.199.136-.36-.361.135-.199a2.024 2.024 0 0 0 .143-2.03 4.958 4.958 0 0 1-.23-.554 2.024 2.024 0 0 0-1.536-1.334l-.236-.044v-.51l.236-.044a2.024 2.024 0 0 0 1.536-1.334 4.96 4.96 0 0 1 .23-.554 2.024 2.024 0 0 0-.143-2.03l-.136-.199.361-.36.199.135a2.024 2.024 0 0 0 2.03.143c.179-.087.364-.164.554-.23a2.024 2.024 0 0 0 1.334-1.536ZM8.5 2h3l.274 1.46c.034.185.17.333.348.394.248.086.49.186.722.3.17.082.37.074.526-.033l1.226-.839 2.122 2.122-.84 1.226a.524.524 0 0 0-.032.526c.114.233.214.474.3.722.061.177.21.314.394.348L18 8.5v3l-1.46.274a.524.524 0 0 0-.394.348 6.47 6.47 0 0 1-.3.722.524.524 0 0 0 .033.526l.839 1.226-2.122 2.122-1.226-.84a.524.524 0 0 0-.526-.032 6.477 6.477 0 0 1-.722.3.524.524 0 0 0-.348.394L11.5 18h-3l-.274-1.46a.524.524 0 0 0-.348-.394 6.477 6.477 0 0 1-.722-.3.524.524 0 0 0-.526.033l-1.226.839-2.122-2.122.84-1.226a.524.524 0 0 0 .032-.526 6.453 6.453 0 0 1-.3-.722.524.524 0 0 0-.394-.348L2 11.5v-3l1.46-.274a.524.524 0 0 0 .394-.348c.086-.248.186-.49.3-.722a.524.524 0 0 0-.033-.526l-.839-1.226 2.122-2.122 1.226.84a.524.524 0 0 0 .526.032 6.46 6.46 0 0 1 .722-.3.524.524 0 0 0 .348-.394L8.5 2Zm3 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm1.5 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
